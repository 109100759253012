import React from 'react';
import { Typography, Container, Grid, Link } from '@material-ui/core';
import SocialMediaLinks from './SocialMediaLinks';
import 'styles/Footer.css';

const Footer = () => {

  return (
    <div className='footer'>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className='text'>
              Contact Us
            </Typography>
            <Typography variant="body2" className='text'>
              Email: info@fitlerfurryfriends.com
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className='text'>
              Quick Links
            </Typography>
            <Typography variant="body2" className='text'>
              <Link href="/" className='linkText'>
                Home
              </Link>
              <br />
              <Link href="/about-us" className='linkText'>
                About Us
              </Link>
              <br />
              <Link href="/services" className='linkText'>
                Services
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className='text'>
              Connect with Us
            </Typography>
            <SocialMediaLinks className='text' />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
