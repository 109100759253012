// ImageService.js
const dogNames = ['Hutch', 'Frankie', 'Bentley', 'Tilly', 'Scout & Finni', 'Hutch', 'Hutch', 'Kaia', 'Willow', 'Griffin', 'Maple', 'Frankie', 'Willow', 'Teddy', 'Frankie', 'Atlas', 'Ollie', 'Daisy', 'Bentley', 'Scout & Finni', 'Hemingway', 'Shirley', 'Andi', 'Winston', 'Winston', 'Shirley', 'Shirley', 'Shirley', 'Finn', 'Mia', 'Hemingway', 'Mabel', 'Koda', 'Koda', 'Maple', '', 'Chili', 'Tuna', 'Jackson', 'Rocky', 'Bruce', 'Lola', 'Miya Grace', 'Winston', 'Moon', 'Phoenix', 'Bentley', 'Sofie', 'Victory & Murphy', 'Millie', 'Lucky', 'Roscoe', 'Pickle', 'Pepper', 'Frankie', 'Simba', 'Bennie', 'Frankie', 'Ella', 'Eevie & Cocoa', 'Duke', 'Duke', 'Winston', 'Finni', 'Scout', 'Bean', 'Daisy', 'Jax', 'Drogo', 'Savannah', 'Jax', 'Jax & Drogo', 'Rosco', 'Bentley', 'Bentley', 'Scout & Finni', 'Lumi', 'Scout & Finni', 'Flynn', 'Hutch', 'Hutch', 'Wallace', 'Wallace', 'Wallace', 'Hutch', 'Hutch', 'Hemingway', 'Hemingway', 'Hemingway', 'Hemingway', 'Koda', 'Tumi', 'Buddy', 'Flynn', 'Finn', 'Lumi', 'Wallace', 'Franklin', 'Archie', 'Wallace', 'Hemingway', 'Whiskey', 'Coco & Chanel', 'Chanel', 'Lou', 'Lou', 'Lou', 'Fia', 'Ollie & Clark', 'Roo', 'Tumi & Shoogi', 'Shoogie', 'Tumi', 'Shoogi', 'Chelsea', 'Chelsea', 'Chelsea'];

export const generateDogImageArray = (imageCount) => {
  const dogImageArray = [];

  for (let i = 1; i <= imageCount; i++) {
    let imageObject = {};
    let extension = 'jpeg';
    if ([1, 29, 47, 55, 58, 64, 65, 67, 74, 75, 76, 78, 79, 80, 81, 85, 86, 87, 88, 89, 105, 106, 107, 113, 115]
      .includes(i)) {
      extension = 'jpg';
    }
    if (i - 1 == 0) {
      imageObject = {
        img: `https://all-doggo-photos.s3.amazonaws.com/DOGGOS/${i}.${extension}`,
        title: dogNames[i - 1],
        rows: 2,
        cols: 2,
        featured: true,
      };
    } else {
      imageObject = {
        img: `https://all-doggo-photos.s3.amazonaws.com/DOGGOS/${i}.${extension}`,
        title: dogNames[i - 1],
      };
    }
    if (![36, 63, 71, 77, 23, 35].includes(i)) {
      dogImageArray.push(imageObject);
    }
  }

  return dogImageArray;
};

const dogImages = generateDogImageArray(dogNames.length);

export const getRandomImages = (count = 16) => {
  const shuffledItems = [...dogImages].sort(() => Math.random() - 0.5);
  const shuffledNames = shuffledItems.map((item) => item.title);

  const uniqueNames = [...new Set(shuffledNames.slice(0, count))];
  const isScoutAndFinniIncluded = shuffledNames.slice(0, count).includes('Scout & Finni');
  const isScoutOrFinniIncluded = shuffledNames.slice(0, count).includes('Scout') || shuffledNames.slice(0, count).includes('Finni');
  const isDrogoAndJaxIncluded = shuffledNames.slice(0, count).includes('Jax & Drogo');
  const isJaxOrDrogoIncluded = shuffledNames.slice(0, count).includes('Jax') || shuffledNames.slice(0, count).includes('Drogo');
  const isCocoAndChanelIncluded = shuffledNames.slice(0, count).includes('Coco & Chanel');
  const isCocoOrChanelIncluded = shuffledNames.slice(0, count).includes('Chanel') || shuffledNames.slice(0, count).includes('Coco');

  if (uniqueNames.length !== count || (isScoutAndFinniIncluded && isScoutOrFinniIncluded) || (isDrogoAndJaxIncluded && isJaxOrDrogoIncluded) || (isCocoAndChanelIncluded && isCocoOrChanelIncluded)) {
    return getRandomImages(count); // Add 'return' before the recursive call
  }

  return shuffledItems.slice(0, count);
};
