// LoginPage.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Login from 'components/Login'; // Assuming you have a Login component
import Signup from 'components/SignUp'; // Assuming you have a Signup component
import { Helmet } from 'react-helmet';

const LoginPage = () => {
  const [isLogin, setIsLogin] = useState(true);

  const handleToggle = () => {
    setIsLogin((prev) => !prev);
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <Helmet>
        <title>Login/Sign Up</title>
      </Helmet>
      {isLogin ? <Login /> : <Signup />}
      <p>
        <div style={{ marginTop: '8vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {isLogin ? 'Don\'t have an account? ' : 'Already have an account? '}
          <Link onClick={handleToggle}>
            {isLogin ? 'Sign Up here' : 'Login here'}
          </Link>
        </div>
      </p>
    </div>
  );
};

export default LoginPage;
