// MyBookings.js
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BookingCard from 'components/BookingCard';
import { selectUser } from 'slices/userSlice';
import Grid from '@material-ui/core/Grid';
import { sortBookingsByStartDateTime } from 'services/BookingService';
import 'styles/MyBookings.css';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { handleCancelClick, handleConfirmCancel, handleCancelDialogClose } from 'handlers/bookingHandlers';
import { Helmet } from 'react-helmet';

const pino = require('pino')();

const MyBookings = () => {
  const user = useSelector(selectUser);
  const accountNumber = user.accountNumber;

  const [bookings, setBookings] = useState([]);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetch(`http://localhost:5000/bookings/account/${accountNumber}`);
        const data = await response.json();

        const filteredBookings = data.filter(
          (booking) =>
            booking.status === 'Awaiting Confirmation' || booking.status === 'Active'
        );

        setBookings(sortBookingsByStartDateTime(filteredBookings));
      } catch (error) {
        pino.error('Error fetching bookings:', error);
      }
    };

    fetchBookings();
  }, [accountNumber, user, bookings]);

  return (
    <div className="container">
      <Helmet>
        <title>My Bookings</title>
      </Helmet>
      <h2>Your Bookings</h2>
      {!Array.isArray(bookings) || bookings.length === 0 ? (
        <p>No bookings found.</p>
      ) : (
        <Grid container spacing={2} className="bookingsGrid">
          {bookings.map((booking) => (
            <Grid item key={booking._id} xs={12} sm={6} md={4} lg={3}>
              <BookingCard
                booking={booking}
                onCancel={() => handleCancelClick(booking._id.toString(), setConfirmationDialogOpen, setSelectedBookingId)}
                className="card"
              />
            </Grid>
          ))}
        </Grid>
      )}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={() => handleCancelDialogClose(setConfirmationDialogOpen)}
        onConfirm={() => handleConfirmCancel(selectedBookingId, setConfirmationDialogOpen, setBookings)}
        title="Confirmation"
        message="Are you sure you want to cancel this booking?"
      />
    </div>
  );
};

export default MyBookings;
