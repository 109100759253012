// BookingService.js

const sortBookingsByStartDateTime = (bookings) => {
  return [...bookings].sort((a, b) => {
    const dateA = new Date(a.startDateTime);
    const dateB = new Date(b.startDateTime);
    return dateA - dateB;
  });
};

export { sortBookingsByStartDateTime };
