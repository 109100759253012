// AddPetDialog.js

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import AddPet from './AddPet';

const AddPetDialog = ({ open, onClose, userPets, setUserPets }) => {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add a New Pet</DialogTitle>
      <DialogContent>
        <AddPet onClose={onClose} setUserPets={setUserPets} userPets={userPets} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

AddPetDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setUserPets: PropTypes.func.isRequired,
  userPets: PropTypes.array.isRequired
};

export default AddPetDialog;
