import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Rating from '@mui/material/Rating';

const ReviewCard = ({ reviews }) => {
  const [index, setIndex] = useState(0);

  const nextReview = () => setIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  const prevReview = () => setIndex((prevIndex) =>
    (prevIndex - 1 + reviews.length) % reviews.length);

  return (
    <div style={styles.cardContainer}>
      <button style={styles.button} onClick={prevReview} disabled={index === 0}>
        Previous
      </button>
      <div style={styles.card}>
        <h2>{reviews[index].customerName}</h2><p>Service: {reviews[index].service}</p>
        <p>{reviews[index].reviewText}</p>
        <p>
          <Rating name="read-only" value={reviews[index].rating} readOnly />
        </p>
        <p>Date: {reviews[index].date}</p>
      </div>
      <button style={styles.button} onClick={nextReview} disabled={index === reviews.length - 1}>
        Next
      </button>
    </div>
  );
};

ReviewCard.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      customerName: PropTypes.string.isRequired,
      reviewText: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      service: PropTypes.string.isRequired
    })
  ).isRequired,
};

const styles = {
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '600px', // Set your desired width
    minHeight: '425px',
    margin: '0 auto',
    overflow: 'hidden', // Ensure that the container hides overflowing content
  },
  card: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: '#001F3F',
    color: 'white',
    maxWidth: '400px',
    width: '100%', // Adjusted to take full width
    transition: 'transform 0.5s ease-in-out', // Use 'ease-in-out' for a smoother transition
    textAlign: 'center'
  },
  button: {
    padding: '8px 16px',
    background: '#2196F3',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default ReviewCard;
