import React from 'react';
import ReviewContainer from 'components/ReviewContainer';
import CustomerReviews from 'assets/data/CustomerReviews';
import { Typography, Box, Container, Divider } from '@mui/material';
import calculateAverageRating from 'services/TestimonialsService';
import { Helmet } from 'react-helmet';

const TestimonialsPage = () => {
  const averageRating = calculateAverageRating(CustomerReviews);

  return (
    <div>
      <Helmet>
        <title>Testimonials</title>
      </Helmet>
      <Container>
        <Box mt={4} mb={2}>
          <Typography variant="h4" align="center" color="primary">
            Customer Testimonials
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="h6" align="center" color="textSecondary">
            Average Rating: {averageRating.toFixed(1)}/5 ({CustomerReviews.length})
          </Typography>
        </Box>
        <ReviewContainer reviews={CustomerReviews} />
      </Container>
    </div>
  );
};



export default TestimonialsPage;
