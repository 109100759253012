import React, { useState } from 'react';
import { Card, CardContent, Typography, Divider, List, ListItem, ListItemText, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const RatesCard = () => {
  const [openModal, setOpenModal] = useState(null);

  const handleOpenModal = (serviceOption) => {
    setOpenModal(serviceOption);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const services = [
    {
      category: 'Walks',
      options: [
        { title: '15 Minute Walk', price: '$18 per walk', description: 'Provide your pet with a refreshing 15-minute outdoor break. Ideal for a quick energy boost, a stretch, and a bathroom break during the day.' },
        { title: '30 Minute Walk', price: '$24 per walk', description: 'Extend the joy with a 30-minute walk, offering additional time for exercise, exploration, and play. Perfect for pets with boundless energy.' },
        { title: '60 Minute Walk', price: '$36 per walk', description: 'Embark on a grand 60-minute outdoor adventure filled with joy, play, and exploration. Tailored for the adventurous and playful pets who love being outside.' },
      ],
    },
    {
      category: 'Drop In Visits',
      options: [
        {
          title: '15 Minute Visit',
          price: '$18 per visit',
          description: 'Conduct a brief 15-minute check-in to ensure the well-being of your pet. This includes necessary activities such as playtime, providing a snack, and offering affection. Even short visits contribute positively to their overall happiness.'
        },
        {
          title: '30 Minute Visit',
          price: '$24 per visit',
          description: 'Allocate thirty minutes of dedicated attention to meet your pet\'s needs—engaging in playtime, offering belly rubs, and possibly providing a treat or two. Every pet deserves a focused and enriching interaction.'
        },
        {
          title: '60 Minute Visit',
          price: '$36 per visit',
          description: 'Provide extended care with a full 60-minute visit, ideal for pets who thrive on extended play and companionship. Tailored for those who benefit from a more extended interaction, this package includes play, pampering, and attentive care, ensuring your furry friend receives the attention they need.'
        },
      ],

    },
    {
      category: 'Dog Sitting',
      options: [
        { title: 'Overnight/Constant Care', price: '$90', description: 'The ultimate sleepover for your pup! Ensures a night of snuggles, dreams, and midnight snacks. It’s like a slumber party, but with tails wagging and hearts full of love.' },
        { title: 'Intermittent Check-Ins', price: '$70', description: 'This service is designed for pet owners who need assistance and care throughout the day. Whether you\'re away or require extra help, we\'re here to provide comforting check-ins. During each visit, we happily engage in walks, playtime, and ensure your pet is well-fed. Trust us to be a reliable companion for your furry friend, offering the care and attention they deserve.' },
      ],
    },
    {
      category: 'Cat Sitting',
      options: [
        { title: 'Overnight/Constant Care', price: '$75', description: 'A night of purrs and cuddles! Like a magical sleepover filled with cozy blankets, feather toys, and the soothing sound of contented purring. Sweet dreams, feline friend!' },
        { title: 'Intermittent Check-Ins', price: '$50', description: 'This service is designed for cat owners seeking assistance and care throughout the day. Whether you\'re away or need extra support, we\'re here to provide comforting check-ins. During each visit, we happily engage in playtime, ensure your cat is well-fed, and create a soothing environment. Trust us to be a reliable companion for your feline friend, offering the care and attention they deserve.' },
      ],
    },
  ];

  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', marginTop: 5, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h6" sx={{ textAlign: 'center', mb: 2, color: '#333' }}>
          Rates
        </Typography>

        {services.map((service, index) => (
          <div key={service.category}>
            <List>
              <ListItem>
                <ListItemText primary={service.category} sx={{ color: '#00796b' }} />
              </ListItem>
              <Divider />
              {service.options.map((option) => (
                <ListItem key={option.title}>
                  <ListItemText primary={option.title} secondary={option.price} />
                  <Button variant="outlined" onClick={() => handleOpenModal(option)}>
                    Learn More
                  </Button>
                </ListItem>
              ))}
            </List>
            {index < services.length - 1 && <Divider />} {/* Add divider between categories */}
          </div>
        ))}

        <Typography variant="subtitle2" sx={{ marginTop: 2, color: '#00796b' }}>
          If you don&apos;t find the specific service you&apos;re looking for, we&apos;re delighted to collaborate with you and tailor a custom service to meet your unique needs.
        </Typography>

        <Typography variant="subtitle2" sx={{ marginTop: 2, color: '#d32f2f' }}>
          * Note: If you require our services within 24 hours, the cost will be increased by 20% to accommodate your immediate needs and provide exceptional care for your pets.
        </Typography>

        {openModal && (
          <Dialog open={Boolean(openModal)} onClose={handleCloseModal}>
            <DialogTitle>{openModal.title}</DialogTitle>
            <DialogContent>
              <Typography variant="body2">{openModal.description}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </CardContent>
    </Card>
  );
};

export default RatesCard;
