const dogBreeds = [
  'Lhasa Apso',
  'Shiba Inu',
  'Toy Fox Terrier',
  'Segugio Italiano',
  'Newfoundland',
  'Bohemian Shepherd',
  'Braque du Bourbonnais',
  'American Water Spaniel',
  'Sloughi',
  'Boxer',
  'Kai Ken',
  'Chesapeake Bay Retriever',
  'Kishu Ken',
  'Mountain Cur',
  'Spanish Water Dog',
  'French Bulldog',
  'German Spitz',
  'Lapponian Herder',
  'Bavarian Mountain Scent Hound',
  'Plott Hound',
  'Curly-Coated Retriever',
  'Bloodhound',
  'Rat Terrier',
  'Labrador Retriever',
  'Welsh Terrier',
  'Pyrenean Shepherd',
  'Deutscher Wachtelhund',
  'Dogue de Bordeaux',
  'Portuguese Pointer',
  'Scottish Deerhound',
  'Hovawart',
  'Italian Greyhound',
  'Doberman Pinscher',
  'Rafeiro do Alentejo',
  'Miniature Pinscher',
  'Russian Tsvetnaya Bolonka',
  'Porcelaine',
  'Estrela Mountain Dog',
  'Catahoula Leopard Dog',
  'Tornjak',
  'Jindo',
  'Vizsla',
  'Karelian Bear Dog',
  'Maltese',
  'Brittany',
  'Eurasier',
  'Pyrenean Mastiff',
  'Russell Terrier',
  'Barbet',
  'Caucasian Shepherd',
  'Mastiff',
  'Australian Kelpie',
  'Sussex Spaniel',
  'Norrbottenspets',
  'Spinone Italiano',
  'Hamiltonstovare',
  'Toy Poodle',
  'Great Pyrenees',
  'Biewer Terrier',
  'Borzoi',
  'English Cocker Spaniel',
  'Greyhound',
  'Yorkshire Terrier',
  'Belgian Tervuren',
  'English Setter',
  'Cocker Spaniel',
  'Lagotto Romagnolo',
  'Black Russian Terrier',
  'Silky Terrier',
  'Broholmer',
  'Old English Sheepdog',
  'Berger Picard',
  'Rhodesian Ridgeback',
  'Jagdterrier',
  'Danish-Swedish Farmdog',
  'Neapolitan Mastiff',
  'Miniature American Shepherd',
  'Pointer',
  'Japanese Spitz',
  'Thai Ridgeback',
  'Bull Terrier',
  'Czechoslovakian Vlcak',
  'English Foxhound',
  'Bolognese',
  'Australian Cattle Dog',
  'Pekingese',
  'Swedish Lapphund',
  'Miniature Poodle',
  'Leonberger',
  'Weimaraner',
  'Otterhound',
  'Poodle',
  'English Toy Spaniel',
  'Canaan Dog',
  'American English Coonhound',
  'Japanese Chin',
  'Irish Wolfhound',
  'Pomeranian',
  'Parson Russell Terrier',
  'Finnish Lapphund',
  'Harrier',
  'Shih Tzu',
  'Border Collie',
  'Ibizan Hound',
  'German Longhaired Pointer',
  'American Staffordshire Terrier',
  'German Shepherd Dog',
  'Sealyham Terrier',
  'Grand Basset Griffon Vendeen',
  'Papillon',
  'Belgian Laekenois',
  'Chow Chow',
  'Dalmatian',
  'Norwegian Elkhound',
  'Golden Retriever',
  'Petit Basset Griffon Vendeen',
  'Yakutian Laika',
  'Bedlington Terrier',
  'Teddy Roosevelt Terrier',
  'Whippet',
  'Schipperke',
  'Irish Water Spaniel',
  'Siberian Husky',
  'American Foxhound',
  'Great Dane',
  'Icelandic Sheepdog',
  'Bracco Italiano',
  'Belgian Malinois',
  'Cavalier King Charles Spaniel',
  'Brussels Griffon',
  'Cesky Terrier',
  'Field Spaniel',
  'Bouvier des Flanders',
  'Central Asian Shepherd Dog',
  'Portuguese Podengo',
  'Black and Tan Coonhound',
  'Mudi',
  'Norwegian Lundehund',
  'Akita',
  'Drentsche Patrijshond',
  'Norwegian Buhund',
  'Flat-Coated Retriever',
  'Boykin Spaniel',
  'West Highland White Terrier',
  'Appenzeller Sennenhunde',
  'Small Munsterlander Pointer',
  'Spanish Mastiff',
  'Australian Stumpy Tail Cattle Dog',
  'Dachshund',
  'Kromfohrlander',
  'Chihuahua',
  'Komondor',
  'Samoyed',
  'Pharaoh Hound',
  'Croatian Sheepdog',
  'Portuguese Sheepdog',
  'Saluki',
  'Miniature Bull Terrier',
  'Tibetan Terrier',
  'Basenji',
  'Lakeland Terrier',
  'Tosa',
  'Cane Corso',
  'Pudelpointer',
  'Tibetan Mastiff',
  'American Hairless Terrier',
  'Australian Terrier',
  'Anatolian Shepherd Dog',
  'Azawakh',
  'Dogo Argentino',
  'Briard',
  'Puli',
  'German Pinscher',
  'German Shorthaired Pointer',
  'Welsh Springer Spaniel',
  'Airedale Terrier',
  'Collie',
  'Hokkaido',
  'Irish Terrier',
  'Norfolk Terrier',
  'Portuguese Podengo Pequeno',
  'Lancashire Heeler',
  'Gordon Setter',
  'Skye Terrier',
  'Bichon Frise',
  'Tibetan Spaniel',
  'American Eskimo Dog',
  'Taiwan Dog',
  'Standard Schnauzer',
  'Treeing Tennessee Brindle',
  'Scottish Terrier',
  'Afghan Hound',
  'Dandie Dinmont Terrier',
  'Cirneco dell\'Etna',
  'Australian Shepherd',
  'Transylvanian Hound',
  'Staffordshire Bull Terrier',
  'Saint Bernard',
  'Keeshond',
  'Slovensky Cuvac',
  'Irish Setter',
  'Belgian Sheepdog',
  'Löwchen',
  'Peruvian Inca Orchid',
  'Kerry Blue Terrier',
  'Soft Coated Wheaten Terrier',
  'Chinese Shar-Pei',
  'Drever',
  'Bearded Collie',
  'German Wirehaired Pointer',
  'Greater Swiss Mountain Dog',
  'Braque Francais Pyrenean',
  'Bluetick Coonhound',
  'Clumber Spaniel',
  'Irish Red and White Setter',
  'Entlebucher Mountain Dog',
  'Bernese Mountain Dog',
  'Norwich Terrier',
  'Wirehaired Vizsla',
  'Nova Scotia Duck-Tolling Retriever',
  'Shetland Sheepdog',
  'Finnish Spitz',
  'Giant Schnauzer',
  'Dutch Shepherd',
  'Slovakian Wirehaired Pointer',
  'Xoloitzcuintli',
  'Stabyhoun',
  'Standard Poodle',
  'American Leopard Hound',
  'Chinook',
  'Bullmastiff',
  'Romanian Mioritic Shepherd Dog',
  'Kuvasz',
  'Beagle',
  'French Spaniel',
  'Russian Toy',
  'Basset Fauve de Bretagne',
  'Redbone Coonhound',
  'Boerboel',
  'Cairn Terrier',
  'Slovensky Kopov',
  'Coton de Tulear',
  'Glen of Imaal Terrier',
  'Basset Hound',
  'Pug',
  'Pembroke Welsh Corgi',
  'Miniature Schnauzer',
  'Swedish Vallhund',
  'Cardigan Welsh Corgi',
  'Beauceron',
  'Wirehaired Pointing Griffon',
  'Boston Terrier',
  'Treeing Walker Coonhound',
  'Chinese Crested',
  'Shikoku',
  'Hanoverian Scenthound',
  'Portuguese Water Dog',
  'English Springer Spaniel',
  'Mixed',
  'Alaskan Malamute',
  'Schapendoes',
  'Working Kelpie',
  'Wirehaired Fox Terrier',
  'Nederlandse Kooikerhondje',
  'Manchester Terrier',
  'Affenpinscher',
  'Rottweiler',
  'Pumi',
  'Perro de Presa Canario',
  'Polish Lowland Sheepdog',
  'Smooth Fox Terrier',
  'Carolina Dog',
  'Havanese',
  'Bergamasco',
  'Bulldog',
];

const catBreeds = [
  'Oriental',
  'Russian Blue',
  'Khao Manee',
  'Turkish Van',
  'Tiffany',
  'Manx',
  'Tennessee Rex',
  'Burmese',
  'Persian',
  'Cornish Rex',
  'Norwegian Forest Cat',
  'Highlander',
  'Turkish Angora',
  'American Bobtail',
  'Somali',
  'Chausie',
  'Bengal',
  'Siberian',
  'Maine Coon',
  'Abyssinian',
  'Ragamuffin',
  'Savannah',
  'Exotic Shorthair',
  'Birman',
  'Burmilla',
  'Korn Ja',
  'Nebelung',
  'Donskoy',
  'Himalayan',
  'Scottish Fold',
  'Serengeti',
  'Chartreux',
  'Khao Manee',
  'Pixiebob',
  'Cymric',
  'Toybob',
  'LaPerm',
  'Thai',
  'Korat',
  'Siamese',
  'Domestic Mediumhair',
  'Chantilly-Tiffany',
  'Burmese',
  'Javanese',
  'Kurilian Bobtail',
  'Cheetoh',
  'Minskin',
  'Ukrainian Levkoy',
  'Ragdoll',
  'Munchkin',
  'Sokoke',
  'Havana Brown',
  'Lykoi',
  'Peterbald',
  'Thai',
  'Ocicat',
  'Japanese Bobtail',
  'Tuxedo Cat',
  'Kurilian Bobtail',
  'American Curl',
  'Devon Rex',
  'Siamese Mix',
  'Munchkin',
  'Tonkinese',
  'Korn Ja',
  'Ragdoll',
  'Ocicat',
  'Norwegian Forest Cat',
  'Persian',
  'Somali',
  'Tiffany',
  'Siamese',
  'Korat',
  'Minskin',
  'Russian Blue',
  'Abyssinian',
  'Balinese',
  'Turkish Angora',
  'Turkish Van',
  'Snowshoe',
  'Cheetoh',
  'Skookum',
  'Pixiebob',
  'Thai',
  'Scottish Fold',
  'Birman',
  'Domestic Shorthair',
  'Egyptian Mau',
  'Khao Manee',
  'Sphynx',
  'Ragamuffin',
  'Chausie',
  'Tuxedo Cat',
  'Domestic Mediumhair',
  'Domestic Longhair',
  'Singapura',
  'Kurilian Bobtail',
  'Maine Coon',
  'Serengeti',
  'Cymric',
  'Munchkin',
  'Persian',
  'Himalayan',
  'Balinese',
  'Siberian',
  'American Shorthair',
  'Japanese Bobtail',
  'Russian Blue',
  'Devon Rex',
  'Cornish Rex',
  'Exotic Shorthair',
  'Chantilly-Tiffany',
  'Havana Brown',
  'Sokoke',
  'Minskin',
  'Siamese Mix',
  'American Curl',
  'Bengal',
  'Tuxedo Cat',
  'Chartreux',
  'LaPerm',
  'Egyptian Mau',
  'Sphynx',
  'Tonkinese',
  'Siamese',
  'Siberian',
  'Abyssinian',
  'Balinese',
  'British Shorthair',
  'Birman',
  'Siberian',
  'Ragamuffin',
  'Sphynx',
  'Devon Rex',
  'Norwegian Forest Cat',
  'Abyssinian',
  'Siberian',
  'Cornish Rex',
  'Munchkin',
  'Scottish Fold',
  'LaPerm',
  'Khao Manee',
  'Siamese',
  'Balinese',
  'Lykoi',
  'Cornish Rex',
  'Manx',
  'Siamese Mix',
  'Turkish Van',
  'Maine Coon',
  'Ragdoll',
  'Savannah',
  'Burmese',
  'Abyssinian',
  'Siberian',
  'Korat',
  'Cheetoh',
  'Turkish Angora',
  'Serengeti',
  'Lykoi',
  'Domestic Longhair',
  'Cymric',
  'Turkish Van',
  'Domestic Shorthair',
  'Javanese',
  'Cheetoh',
  'Cornish Rex',
  'Domestic Mediumhair',
  'Himalayan',
  'Snowshoe',
  'Sokoke',
  'Pixiebob',
  'American Curl',
  'Sphynx',
  'Manx',
  'Ragamuffin',
  'Cymric',
  'Toybob',
  'Khao Manee',
  'Peterbald',
  'Maine Coon',
  'Minskin',
  'Sokoke',
  'Abyssinian',
  'Munchkin',
  'Ragdoll',
  'Savannah',
  'Siamese',
  'Devon Rex',
  'Exotic Shorthair',
  'Balinese',
  'Ragamuffin',
  'Scottish Fold',
  'Serengeti',
  'Tonkinese',
  'Birman',
  'Munchkin',
  'Pixiebob',
  'Ragdoll',
  'Russian Blue',
  'Savannah',
  'Scottish Fold',
  'Selkirk Rex',
  'Serengeti',
  'Siamese',
  'Siberian',
  'Singapura',
  'Snowshoe',
  'Sokoke',
  'Somali',
  'Sphynx',
  'Tabby Cat',
  'Thai',
  'Tiffany',
  'Tonkinese',
  'Torbie Cat',
  'Toybob',
  'Tuxedo Cat',
  'Turkish Angora',
  'Turkish Van',
  'Ukrainian Levkoy',
  'Kurilian Bobtail',
  'Skookum',
  'Tennessee Rex',
  'Thai',
  'Turkish Van'
];

export { dogBreeds, catBreeds };
