const customerReviews = [
  {
    id: 4,
    customerName: 'Owen B.',
    reviewText: 'Devon was very helpful',
    date: '12/09/2023',
    service: 'Drop In Visit',
    rating: 5,
  },
  {
    id: 3,
    customerName: 'Avery H.',
    reviewText: 'Would highly recommend Devon to anyone who needs dog walking or drop ins. He’s friendly, professional, and my dog loves him! My dog always stands ups with his tail wagging when he hears Devon arriving. Devon has been flexible with my everchanging schedule as well. We look forward to continuing to use him as a dog walker!',
    date: '11/09/2023',
    service: 'Dog Walking',
    rating: 5,
  },
  {
    id: 2,
    customerName: 'Juan C.',
    reviewText: 'Devon was so great with Daisy! Really communicated and very smooth all around!!',
    date: '09/30/2023',
    service: 'Drop In Visit',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Shaista A.',
    reviewText: 'My dogs love Devon! He has already walked them twice. Hopefully, we can schedule more walks in the future.',
    date: '09/11/2023',
    service: 'Dog Walking',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Emma R.',
    reviewText: 'Maria watched my 2 pups while I was away and took great care of them! This included giving them medications twice daily and some icy walks in the cold! I\'m super grateful that they were well cared for :)',
    date: '01/17/2024',
    service: 'Pet Sitting',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Noelle S.',
    reviewText: 'Maria was one of the best dog sitters we\'ve had! She was excellent at communicating and took great care of our senior dog--including med administration--while we were gone for the day.Would definitely recommend. :) ',
    date: '12/30/2023',
    service: 'Dog Walking',
    rating: 5,
  },

  {
    id: 1,
    customerName: 'Mellisa M',
    reviewText: 'Maria was so communicative the whole time she was staying with Mia, sent pics of them cuddling which gave me the ease to know she was very comfortable with her. I’d 100% book again with Maria - worth every penny <3',
    date: '12/15/2023',
    service: 'Pet Sitting',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Jessica E.',
    reviewText: 'Maria was very great to work with! She is easy to communicate with and took my dog on a great walk! She is very friendly and my dog loved her! Highly recommend!',
    date: '12/07/2023',
    service: 'Dog Walking',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Helen S.',
    reviewText: 'Maria was amazing with our dog Ollie while we were away for a wedding. She was a lovely guest in our home and was so attentive to all of Ollie’s needs. Ollie is not always the best with other dogs, and Maria was great with him.',
    date: '10/01/2023',
    service: 'Pet Sitting',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Taylor M.',
    reviewText: '10 stars. Maria was wonderful with our boy. Able to fit us into her schedule LAST minute. She sat Atlas in our home and was so respectful. From the second she walked in the door, it was 100% clear she was a trustworthy person, as our dog was giving her kisses and bringing her toys (and he is an excellent judge of character). 10/10 would recommend.',
    date: '08/31/2023',
    service: 'Pet Sitting',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Jessie D.',
    reviewText: 'Awesome as always! Teddy love you',
    date: '08/26/2023',
    service: 'Dog Walking',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Micah L.',
    reviewText: 'Maria watched our 9 year old pup for four days and she was wonderful. She stayed at our home and was not only amazing w our dog, when we shared that he may need some extra time spent w him bc he isn’t used to being alone, she was completely on board and made us feel so at ease. I highly recommend using Maria as a walker or sitter!',
    date: '07/31/2023',
    service: 'Pet Sitting',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Madison L.',
    reviewText: 'Maria watched my dog for a long weekend in our home and she was great! Very responsive, caring, and I could tell immediately that my dog was in good hands when she warmed up to Maria right off the bat. This was my first time leaving my dog with someone other than my parents for more than a couple nights, so I was very nervous about it. She has a little bit of separation anxiety and even though which makes it hard for me to trust people. However, I will definitely use Maria in the future!',
    date: '08/08/2023',
    service: 'Dog Walking',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Caroline N.',
    reviewText: 'Maria was so attentive and communicative when she house sit - my girl clearly loved her and we’ll be sure to book her again!',
    date: '07/24/2023',
    service: 'Pet Sitting',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Jessie D.',
    reviewText: 'Great first walk with Maria and my dog Teddy! ? We appreciate your care. Will definitely call upon you again!',
    date: '07/06/2023',
    service: 'Dog Walking',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Carissa A',
    reviewText: 'Devon is prompt, accomodating, friendly, and great with my very energetic pup. I highly recommend his services.',
    date: '02/01/2024',
    service: 'Dog Walking',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Brandon D',
    reviewText: 'Always amazing !',
    date: '02/04/2024',
    service: 'Dog Walking',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Taylor H',
    reviewText: 'Devon is great with my big puppy! Highly recommend',
    date: '02/14/2024',
    service: 'Dog Walking',
    rating: 5,
  },
  {
    id: 1,
    customerName: 'Ellen M',
    reviewText: 'Devon was so easy to communicate with and came right on time! Dusty says thank you!',
    date: '02/20/2024',
    service: 'Dog Walking',
    rating: 5,
  }
  // Add more reviews as needed
];

const sortedReviews = customerReviews.sort((a, b) => new Date(b.date) - new Date(a.date));

export default sortedReviews;
