import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black', // Color when the label is focused
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black', // Color when the input is focused and not outlined
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4CAF50', // Default outline color
      },
      '&:hover fieldset': {
        borderColor: 'black', // Outline color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4CAF50', // Outline color when focused
      },
    },
  },
})(TextField);

export default CustomTextField;
