// src/components/ReviewContainer.js

import React from 'react';
import PropTypes from 'prop-types';
import ReviewCard from 'components/ReviewCard';

const ReviewContainer = ({ reviews }) => {
  return (
    <div style={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
      <ReviewCard reviews={reviews} />
    </div>
  );
};

ReviewContainer.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      customerName: PropTypes.string.isRequired,
      reviewText: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default ReviewContainer;
