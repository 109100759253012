// BookingCard.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import 'styles/BookingCard.css';


const BookingCard = ({ booking, onCancel, onEdit }) => {
  const [petNames, setPetNames] = useState([]);

  useEffect(() => {
    if (booking.pets && Array.isArray(booking.pets) && booking.pets.length > 0) {
      setPetNames(booking.pets.map((pet) => pet.name));
    } else {
      setPetNames([]);
    }
  }, [booking.pets]);

  return (
    <Card className='card'>
      <CardContent>
        <Typography className='serviceName' gutterBottom>
          {booking.serviceName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {new Date(booking.startDateTime).toLocaleString()}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Status: {booking.status}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Pets:
          {petNames.map((petName) => (
            <Chip key={petName} label={petName} style={{ marginRight: 4 }} className='petChip' />
          ))}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EditOutlinedIcon />}
          onClick={onEdit}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CancelOutlinedIcon />}
          onClick={() => onCancel(booking._id.toString())}
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
};

BookingCard.propTypes = {
  booking: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    serviceName: PropTypes.string.isRequired,
    startDateTime: PropTypes.instanceOf(Date).isRequired,
    status: PropTypes.string.isRequired,
    pets: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default BookingCard;
