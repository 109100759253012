import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import PetCard from 'components/PetCard'; // Import the PetCard component
import 'styles/MyPets.css';
import { handleDelete, handleConfirmationClose, handleConfirmationConfirm, handleEdit } from 'handlers/petHandlers';
import ConfirmationDialog from './ConfirmationDialog';

const UserPets = ({ user, setUserPets, userPets }) => {
  const [deletePetId, setDeletePetId] = useState(null);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    const fetchUserPets = async () => {
      try {
        // Assuming you have an endpoint to fetch user pets by accountNumber
        const response = await axios.get(`http://localhost:5000/pets/account/${user.accountNumber}`);
        setUserPets(response.data);
      } catch (error) {
        console.error('Error fetching user pets:', error);
      }
    };

    fetchUserPets();
  }, [user.accountNumber]);

  useEffect(() => {
    setUserPets([]); // Reset the userPets state
  }, [user]);

  useEffect(() => {
    // This block will be executed whenever userPets changes
    // You can perform additional actions or fetch data here if needed
    console.log('userPets changed:', userPets);
  }, [userPets]);

  return (
    <div>
      <div className='petsContainer'>
        {userPets
          .filter((pet) => pet.status === 'Active')
          .map((pet) => (
            <PetCard
              key={pet._id}
              pet={pet}
              onDelete={() => handleDelete(pet._id.toString(), setDeletePetId, setConfirmationDialogOpen)}
              onEdit={() => handleEdit(pet, setUserPets)}
            />
          ))}
      </div>

      {/* Confirmation Modal */}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={() => handleConfirmationClose(setConfirmationDialogOpen, setDeletePetId)}
        onConfirm={() => handleConfirmationConfirm(deletePetId, setConfirmationDialogOpen, setDeletePetId, setUserPets)}
        title="Confirmation"
        message="Are you sure you want to remove this pet?"
      />
    </div>
  );
};

UserPets.propTypes = {
  user: PropTypes.shape({
    accountNumber: PropTypes.number.isRequired,
    // Add other user properties as needed
  }).isRequired,
  setUserPets: PropTypes.func.isRequired,
  userPets: PropTypes.array.isRequired,
};

export default UserPets;
