// bookingHandlers.js
const pino = require('pino');

const logger = pino();

export const handleCancelClick = (bookingId, setConfirmationDialogOpen, setSelectedBookingId) => {
  setConfirmationDialogOpen(true);
  setSelectedBookingId(bookingId);
};

export const handleConfirmCancel = async (selectedBookingId, setConfirmationDialogOpen, setBookings) => {
  try {
    const response = await fetch(`http://localhost:5000/bookings/cancel/${selectedBookingId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        status: 'Cancelled',
      }),
    });

    if (response.ok) {
      setBookings((prevBookings) => {
        if (!prevBookings) {
          logger.warn('prevBookings is null');
          return [];
        }

        return prevBookings.map((booking) =>
          booking._id === selectedBookingId ? { ...booking, status: 'Cancelled' } : booking
        );
      });

      logger.info(`Booking with ID ${selectedBookingId} has been cancelled.`);
    } else {
      logger.error('Failed to cancel booking');
    }
  } catch (error) {
    logger.error('Error cancelling booking:', error);
  }

  setConfirmationDialogOpen(false);
};

export const handleCancelDialogClose = (setConfirmationDialogOpen) => {
  setConfirmationDialogOpen(false);
};
