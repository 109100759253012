import React from 'react';
import NotFoundPage from 'pages/NotFoundPage';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


const Review = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isFromForm = location.state?.fromForm;

  if (!isFromForm) {
    // If not coming from the form, navigate to the home page
    navigate('/');
    return (<NotFoundPage />); // You can also render a loading spinner or message here
  }

  // Retrieve data from the Redux store
  const formData = useSelector((state) => state.form.contactInformation);

  return (
    <div>
      <h2>Review Your Submission</h2>
      <div>
        <strong>Contact Information:</strong>
        <p>Full Name: {formData.fullName}</p>
        <p>Phone Number: {formData.phoneNumber}</p>
        <p>Email Address: {formData.email}</p>
        <p>Preferred Communication: {formData.communicationMethod}</p>
      </div>
      {/* Include summaries for other sections like Service Details, Pet Information, Additional Information, etc. */}
    </div>
  );
};

export default Review;
