import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const NotFound = () => {
  return (
    <Container component="main" maxWidth="xs" style={{ textAlign: 'center', marginTop: '8vh' }}>
      <Typography variant="h2" color="textPrimary" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" color="textSecondary" paragraph>
        Sorry, the page you are looking for cannot be found.
      </Typography>
      <Typography variant="body1" color="textSecondary" paragraph>
        The requested URL may be incorrect, or the page has been moved or deleted.
      </Typography>
    </Container>
  );
};

export default NotFound;
