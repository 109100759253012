import TitlebarImageList from 'components/ImageDisplay';
import React from 'react';
import { Helmet } from 'react-helmet';

const Photos = () => {
  return (
    <div>
      <Helmet>
        <title>Photos</title>
      </Helmet>
      < TitlebarImageList />
    </div>
  );
};

export default Photos;
