// HomePage.js

import React from 'react';
import ReviewCard from 'components/ReviewCard';
import MapComponent from 'components/MapComponent';
import Welcome from 'components/WelcomeHeadline';
import CustomerReviews from 'assets/data/CustomerReviews';
import { Typography, Button, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import 'index.css';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>Fitler Furry Friends</title>
      </Helmet>
      <Welcome />

      {/* Divider */}
      <Divider variant="middle" style={{ margin: '20px 0', borderBottom: '3px solid #001F3F' }} />


      {/* 1. Reviews Section Description */}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography style={{ fontFamily: 'Proxima Nova', fontWeight: 'Bold' }} variant="h4">Here are a couple of reviews from our recent clients</Typography>
      </div>

      {/* 2. Review Card */}
      <ReviewCard reviews={CustomerReviews.slice(0, 3)} />
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography style={{ fontFamily: 'Proxima Nova' }} variant="body1">
          Click below to read more about what our clients think.
        </Typography>
        <Button component={Link} to="/testimonials" variant="outlined" color="primary" style={{ marginTop: '10px' }}>
          Read More Reviews
        </Button>
      </div>
      <Divider variant="middle" style={{ margin: '20px 0', borderBottom: '3px solid #001F3F' }} />

      {/* 3. Our Service Area */}
      <div style={{ textAlign: 'center', marginTop: '20px', fontFamily: 'Proxima Nova' }}>
        <Typography style={{ fontFamily: 'Proxima Nova', fontWeight: 'Bold' }} variant="h4">Our Service Area</Typography>
        <Typography style={{ fontFamily: 'Proxima Nova' }} variant="body1">
          Explore the map to see the area we generally service. While this is our primary service area, we&apos;re open to making exceptions in some cases.
        </Typography>
      </div>

      {/* 4. Map Component */}
      <div className="map-container">
        <MapComponent />
      </div>
      <Divider variant="middle" style={{ margin: '20px 0', borderBottom: '3px solid #001F3F' }} />
    </div>
  );
};

export default HomePage;
