import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [communicationMethod, setCommunicationMethod] = useState(null);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    communicationMethod: '',
  });

  const validateInputs = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!firstName) {
      isValid = false;
      setErrors((prevErrors) => ({ ...prevErrors, firstName: 'Please enter your first name.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
    }

    if (!lastName) {
      isValid = false;
      setErrors((prevErrors) => ({ ...prevErrors, lastName: 'Please enter your last name.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
    }

    if (!phoneNumber || !phoneNumber.match(/^\d{10}$/)) {
      isValid = false;
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: 'Please enter a valid 10-digit phone number.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: '' }));
    }

    if (!email || !email.match(emailRegex)) {
      isValid = false;
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter a valid email address.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    }

    if (!password || password.length < 8) {
      isValid = false;
      setErrors((prevErrors) => ({ ...prevErrors, password: 'Password must be at least 8 characters.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, password: '' }));
    }

    if (!communicationMethod) {
      isValid = false;
      setErrors((prevErrors) => ({ ...prevErrors, communicationMethod: 'Please select a communication preference.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, communicationMethod: '' }));
    }

    return isValid;
  };


  const handleSignup = async () => {
    if (validateInputs()) {
      try {
        await axios.post('http://localhost:5000/users/create', {
          firstName,
          lastName,
          phoneNumber,
          email,
          communicationMethod,
          password,
        });

        console.log('User created successfully');
        setSignupSuccess(true);
        window.location.href = 'http://localhost:3000/login';
        // Handle success, maybe redirect to login or show a success message
      } catch (error) {
        console.error('Error creating user:', error);
        // Handle error, maybe show an error message to the user
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div style={{ marginTop: '8vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <TextField
          label="First Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          helperText={errors.firstName}
          error={Boolean(errors.firstName)}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          helperText={errors.lastName}
          error={Boolean(errors.lastName)}
        />
        <TextField
          label="Phone Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          helperText={errors.phoneNumber}
          error={Boolean(errors.phoneNumber)}
        />
        <TextField
          label="Email Address"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          helperText={errors.email}
          error={Boolean(errors.email)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          helperText={errors.password}
          error={Boolean(errors.password)}
        />
        <Typography component="h5" variant="h6">
          Communication Preference
        </Typography>
        <RadioGroup
          aria-label="communication-method"
          name="communicationMethod"
          value={communicationMethod}
          onChange={(e) => setCommunicationMethod(e.target.value)}
          style={{ marginTop: '1em', flexDirection: 'row' }}
        >
          <FormControlLabel value="text" control={<Radio />} label="Text Message" />
          <FormControlLabel value="email" control={<Radio />} label="Email" />
          <FormControlLabel value="phone" control={<Radio />} label="Phone Call" />
        </RadioGroup>
        <Typography color="error">{errors.communicationMethod}</Typography>
        <Button variant="contained" color="primary" fullWidth onClick={handleSignup}>
          Sign Up
        </Button>
        {signupSuccess && (
          <Typography style={{ color: 'green', marginTop: '1em' }}>
            Account sign up successful. Redirecting you to the login page...
          </Typography>
        )}
      </div>
    </Container>
  );
};

export default Signup;
