import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import 'styles/PetCard.css';

const PetCard = ({ pet, onDelete, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedPet, setEditedPet] = useState({
    name: pet.name,
    age: pet.age,
    type: pet.type,
    breed: pet.breed,
  });
  const [validationError, setValidationError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPet((prevPet) => ({
      ...prevPet,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
    setValidationError(''); // Clear any previous validation error
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedPet({
      name: pet.name,
      age: pet.age,
      type: pet.type,
      breed: pet.breed,
    });
    setValidationError('');
  };

  const handleSave = () => {
    // Perform client-side validation
    if (editedPet.type !== 'cat' && editedPet.type !== 'dog') {
      setValidationError('Type must be either "cat" or "dog"');
      return;
    }

    // Send the edited pet to the parent component for further processing
    onEdit(pet._id, editedPet);

    // Exit edit mode and clear validation error
    setIsEditing(false);
    setValidationError('');
  };

  return (
    <Card className='card'>
      <CardContent>
        {validationError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {validationError}
          </Alert>
        )}

        {isEditing ? (
          <>
            <TextField
              label="Name"
              name="name"
              value={editedPet.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              className='editTextField'
            />
            <TextField
              label="Age"
              name="age"
              value={editedPet.age}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              className='editTextField'
            />
            <TextField
              label="Type"
              name="type"
              value={editedPet.type}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              className='editTextField'
            />
            <TextField
              label="Breed"
              name="breed"
              value={editedPet.breed}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              className='editTextField'
            />
          </>
        ) : (
          <>
            <Typography variant="h5" component="div">
              {pet.name}
            </Typography>
            <Typography color="text.secondary" gutterBottom>
              Type: {pet.type}
            </Typography>
            <Typography color="text.secondary" gutterBottom>
              Breed: {pet.breed}
            </Typography>
            <Typography color="text.secondary">
              Age: {pet.age}
            </Typography>
          </>
        )}
      </CardContent>
      <CardActions>
        {isEditing ? (
          <>
            <Button size="small" onClick={handleSave}>
              Save
            </Button>
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <IconButton color="error" onClick={() => onDelete(pet._id)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
            {/* You can add additional actions or buttons here */}
            <Button size="small">Details</Button>
          </>
        )}
      </CardActions>
    </Card>
  );
};

PetCard.propTypes = {
  pet: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    age: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    breed: PropTypes.string.isRequired,
    // Add other pet properties as needed
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default PetCard;
