import RatesCard from 'components/Rates';
import BookingButton from 'components/BookingButton';
import React from 'react';
import { Helmet } from 'react-helmet';

const Rates = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Helmet>
        <title>Rates</title>
      </Helmet>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <BookingButton />
      </div>
      <RatesCard />
      <br />
    </div >
  );
};

export default Rates;
