import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { selectUser, logoutUser } from 'slices/userSlice'; // Update the path accordingly
import UserMenu from 'components/UserMenu';


const pages = ['About Us', 'Photos', 'Services', 'Book a Service', 'Testimonials'];

const Navbar = () => {
  const user = useSelector(selectUser);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dispatch = useDispatch();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    // Dispatch the logout action
    dispatch(logoutUser());
  };

  return (
    <AppBar position="static" sx={{ background: '#001F3F !important', color: 'white !important', boxShadow: 'none !important', maxHeight: '80px !important' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div className='navLogo'>
            <Button key={'Home'}
              component={Link}
              to={'/'}>
              <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 30 24" width="36">
                <path d="M0 0h24v24H0z" fill="none" />
                <circle cx="4.5" cy="9.5" r="2.5" fill="#ffffff" /> {/* Change the fill color */}
                <circle cx="9" cy="5.5" r="2.5" fill="#ffffff" /> {/* Change the fill color */}
                <circle cx="15" cy="5.5" r="2.5" fill="#ffffff" /> {/* Change the fill color */}
                <circle cx="19.5" cy="9.5" r="2.5" fill="#ffffff" /> {/* Change the fill color */}
                <path d="M17.34 14.86c-.87-1.02-1.6-1.89-2.48-2.91-.46-.54-1.05-1.08-1.75-1.32-.11-.04-.22-.07-.33-.09-.25-.04-.52-.04-.78-.04s-.53 0-.79.05c-.11.02-.22.05-.33.09-.7.24-1.28.78-1.75 1.32-.87 1.02-1.6 1.89-2.48 2.91-1.31 1.31-2.92 2.76-2.62 4.79.29 1.02 1.02 2.03 2.33 2.32.73.15 3.06-.44 5.54-.44h.18c2.48 0 4.81.58 5.54.44 1.31-.29 2.04-1.31 2.33-2.32.31-2.04-1.3-3.49-2.61-4.8z" fill="#ffffff" /> {/* Change the fill color */}
              </svg>
            </Button>
          </div>
          <div className='navCompanyName'>
            <Button
              key={'Home'}
              component={Link}
              to={'/'}
              sx={{ my: 2, color: 'white', marginLeft: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <Typography
                variant="h6"
                noWrap
                component="a"

                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'Open Sans !important', // Use a playful font
                  fontSize: '1.8rem !important',
                  fontWeight: 'bold !important',
                  color: 'white !important', // Change text color
                  textDecoration: 'none !important',
                }}
              >
                Fitler Furry Friends
              </Typography>
            </Button>
          </div>

          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            {/* Mobile menu button */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            {/* Desktop navigation links */}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  component={Link}
                  to={`/${page.toLowerCase().replace(/\s/g, '-')}`}
                  sx={{ my: 2, color: 'white', marginLeft: 2 }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              {user.firstName ? (
                <>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt={user.firstName} src="/static/images/avatar/2.jpg" />
                    </IconButton>
                  </Tooltip>
                  <UserMenu anchorEl={anchorElUser} onClose={handleCloseUserMenu} handleLogout={handleLogout} user={user} />
                </>
              ) : (
                <Button color="inherit" component={Link} to="/login" sx={{ fontWeight: 'bold', marginLeft: 2 }}>
                  Login / Sign Up
                </Button>
              )}
            </Box>
          </Box>

          {/* Mobile menu with all page options */}
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: 'block', md: 'none' }, '& .MuiButton-root': { color: '#000' } }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                component={Link}
                to={`/${page.toLowerCase().replace(/\s/g, '-')}`}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: 'block' }}
              >
                {page}
              </Button>
            ))}
            {/* Conditionally render login/signup button in mobile menu */}
            {user.firstName ? (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user.firstName} src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
                <UserMenu anchorEl={anchorElUser} onClose={handleCloseUserMenu} handleLogout={handleLogout} user={user} />
              </>
            ) : (
              <Button color="inherit" component={Link} to="/login">
                Login / Sign Up
              </Button>
            )}
          </Menu>

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
