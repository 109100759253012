import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    communicationMethod: '',
    accountNumber: '',
    id: null,
  },
  loading: false,
  error: null,
};

// Async thunk to fetch user ID
export const fetchUserId = createAsyncThunk('user/fetchUserId', async ({ email, phoneNumber }) => {
  try {
    const response = await axios.post('http://localhost:5000/users/getUserId', { email, phoneNumber });
    console.log('response', response, response.data);
    return response.data.id;
  } catch (error) {
    console.error('Error fetching user ID:', error);
    throw error;
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      const { firstName, lastName, email, phoneNumber, communicationMethod, accountNumber, id } = action.payload;
      console.log('payload', action.payload);
      state.user = {
        ...state.user,
        firstName,
        lastName,
        email,
        phoneNumber,
        communicationMethod,
        accountNumber,
        id
      };
    },
    logoutUser: (state) => {
      state.user = initialState.user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.user.id = action.payload;
      })
      .addCase(fetchUserId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { loginUser, logoutUser } = userSlice.actions;

// Selectors
export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
