// components/PetCareComingSoon.js

import React from 'react';
import { Container, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center', // Center the text
  },
  description: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  contactEmail: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
}));

const PetCareComingSoon = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography variant="h2" className={classes.title}>
        Pawsome Adventures Await!
      </Typography>
      <Typography variant="body1" className={classes.description}>
        We&apos;re in the works to bring you an incredible pet care experience. Stay tuned for updates!
      </Typography>
      <Typography variant="body1" className={classes.description}>
        For any questions, please email us at{' '}
        <a href="mailto:info@fitlerfurryfriends.com" className={classes.contactEmail}>
          info@fitlerfurryfriends.com
        </a>
        .
      </Typography>
    </Container>
  );
};

export default PetCareComingSoon;
