import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: '100%',
    height: '400px',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
}));

const MapComponent = () => {
  const classes = useStyles();

  useEffect(() => {
    // Set your Mapbox access token here
    const apiKey = process.env.REACT_APP_MAPBOX_API_KEY;
    mapboxgl.accessToken = apiKey;

    const center = [-75.1784, 39.9462];

    var createGeoJSONCircle = function (center, radiusInKm, points) {
      if (!points) points = 64;

      var coords = {
        latitude: center[1],
        longitude: center[0]
      };

      var km = radiusInKm;

      var ret = [];
      var distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
      var distanceY = km / 110.574;

      var theta, x, y;
      for (var i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);

      return {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'geometry': {
              'type': 'Polygon',
              'coordinates': [ret]
            }
          }]
        }
      };
    };

    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: center,
      zoom: 12.5,
      minZoom: 12,
      maxZoom: 18
    });

    // Add a circle layer to represent the 1-mile radius
    map.on('load', () => {
      map.addSource('circle', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: center,
          },
        },
      });

      map.addSource('polygon', createGeoJSONCircle([-75.1784, 39.9462], 1.61));
      map.addLayer({
        'id': 'polygon',
        'type': 'fill',
        'source': 'polygon',
        'layout': {},
        'paint': {
          'fill-color': 'blue',
          'fill-opacity': 0.6
        }
      });
    });

    // Clean up when the component is unmounted
    return () => map.remove();
  }, []);

  return (
    <Paper className={classes.mapContainer} elevation={3}>
      <div id="map" style={{ width: '100%', height: '100%' }} />
    </Paper>
  );
};

export default MapComponent;
