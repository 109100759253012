import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import 'styles/AboutUs.css';

const AboutUs = () => {

  return (
    <div className='aboutUsContainer'>
      <h2>About Us</h2>

      <Card className='aboutUsCard'>
        <div className='aboutUsImageContainer'>
          <img
            className='aboutUsImage'
            src="https://all-doggo-photos.s3.amazonaws.com/DOGGOS/85.jpg"
            alt="Maria and Hutch"
          />
          <img
            className='aboutUsImage'
            src="https://all-doggo-photos.s3.amazonaws.com/DOGGOS/31.jpeg"
            alt="Devon and Hemingway"
          />

        </div>
        <CardContent>
          <Typography variant="h6" className='aboutUsTitle' gutterBottom>
            Meet Maria & Devon
          </Typography>
          <Typography variant="body2" className='aboutUsParagraph' paragraph>
            Greetings from Maria and Devon, the dynamic duo behind Fitler Furry Friends! As we embark on
            new journeys in our lives, we find solace and joy in the company of our furry friends.
            Maria, set to embark on her journey as a dental student at UPenn in the fall of 2024, has
            chosen Philadelphia as her home for the next chapter of her life. Despite the excitement of
            this new phase, she misses the companionship of her family dog, Tilly. Maria&apos;s deep
            affection for spending time with dogs fuels our dedication to ensuring every pet receives
            the love and care they deserve. On the other hand, Devon, not having grown up
            with dogs, is discovering the wonders of each canine friend. By day, he&apos;s a passionate
            software engineer, but during his free time, he enjoys the delightful company of our
            four-legged pals.
          </Typography>
          <div className='aboutUsImageContainer'>
            <img
              className='aboutUsImage'
              src="https://all-doggo-photos.s3.amazonaws.com/DOGGOS/47.jpg"
              alt="Devon and Bentley"
            />
            <img
              className='aboutUsImage'
              src="https://all-doggo-photos.s3.amazonaws.com/DOGGOS/93.jpeg"
              alt="Maria and Buddy"
            />
          </div>
          <Typography variant="body2" className='aboutUsParagraph'>
            So, why did we dive into the world of pet care with Fitler Furry Friends? Well, the answer is
            simple. We yearned for more time with dogs but recognized the constraints of time and
            resources to commit to our own fur babies. The solution? Extend our care to other pet
            owners who need an extra pair of loving hands for their pups. Our mission is driven by
            the desire to bring joy and companionship to dogs while assisting their owners in
            ensuring the well-being of their beloved pets.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default AboutUs;
