import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { getRandomImages } from 'services/ImageService';
import 'styles/TitleBarImageList.css';

export default function TitlebarImageList() {
  return (
    <div className="image-list-container">
      <ImageList >
        <ImageListItem key="Subheader" className="photo-subheader">
          <ListSubheader
            component="div"
          >
            🐾 Some of the wonderful furry friends we&apos;ve met 🐾
          </ListSubheader>
        </ImageListItem>
        {getRandomImages().map((item) => (
          <ImageListItem key={item.img}>
            <img
              srcSet={`${item.img}`}
              src={`${item.img}`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.title}
            />
            <Typography variant="body2" color="text.secondary">
              {/* Your additional text goes here */}
            </Typography>
          </ImageListItem>

        ))}
      </ImageList>
    </div>
  );
}
