import React from 'react';
import { Typography, Container, makeStyles } from '@material-ui/core';

const picURLs = [
  'https://all-doggo-photos.s3.amazonaws.com/DOGGOS/19.jpeg', // Replace with the URL of your first image
  'https://all-doggo-photos.s3.amazonaws.com/DOGGOS/61.jpeg', // Replace with the URL of your second image
  'https://all-doggo-photos.s3.amazonaws.com/DOGGOS/1.jpg', // Replace with the URL of your third image
];

const useStyles = makeStyles((theme) => ({
  welcomeContainer: {
    display: 'flex',
    flexDirection: 'row', // Set to 'column' if you want them stacked vertically
    justifyContent: 'space-between',
    minHeight: '70vh',
    background: `url(${picURLs[0]}) left/31% no-repeat, url(${picURLs[1]}) center/31% no-repeat, url(${picURLs[2]}) right/31% no-repeat`,
    color: '#fff',
    backgroundColor: '#001F3F',
    textAlign: 'center',
    marginTop: '20px',
    position: 'relative',
    width: '90%'
  },
  '@media (max-width: 900px)': {
    welcomeContainer: {
      background: `url(${picURLs[2]}) center/cover no-repeat`
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.4 )', // Adjust the transparency here
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: '2.5rem',
      fontFamily: 'Open Sans',
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      fontSize: '1.2rem',
      marginBottom: theme.spacing(2),
    },
  },
  overlay: {
    position: 'absolute',
    top: '30%',
    left: 0,
    width: '100%',
    height: '70%',
    backgroundColor: 'rgba(0, 0, 0, 0.4 )', // Adjust the transparency here
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '3.5rem',
    fontFamily: 'Open Sans',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: '2rem',
    marginBottom: theme.spacing(2),
  },
}));

const Welcome = () => {
  const classes = useStyles();

  return (
    <Container className={classes.welcomeContainer}>
      <div className={classes.overlay}>
        <Typography variant="h1" className={classes.title}>
          Fitler Furry Friends
        </Typography>
        <Typography variant="h2" className={classes.subtitle}>
          Dog Walking and Pet Care Service in Philadelphia
        </Typography>
      </div>
    </Container>
  );
};

export default Welcome;
