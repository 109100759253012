import React from 'react';
import AddPet from 'components/AddPet'; // Update the path accordingly
import { Helmet } from 'react-helmet';

const UpdatePets = () => {
  return (
    <div>
      <Helmet>
        <title>Update Pets</title>
      </Helmet>
      <h1>Update Pets</h1>
      <AddPet />
    </div>
  );
};

export default UpdatePets;
