import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const BookingButton = () => {
  return (
    <Button
      component={Link}
      to="/book-a-service"
      variant="contained"
      color="primary"
      size="large"
      style={{ borderRadius: '18px', maxWidth: '95%', marginTop: '10px' }}
    >
      Interested in booking a service?<br /> Click here to request a booking
    </Button>
  );
};

export default BookingButton;
