import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

const SocialMediaLinks = () => {
  const socialMediaAccounts = {
    facebook: 'https://www.facebook.com/profile.php?id=61556530022472',
    instagram: 'https://www.instagram.com/fitlerfurryfriends'
  };

  return (
    <div>
      <Tooltip title="Facebook">
        <IconButton component="a" href={socialMediaAccounts.facebook} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
          <FacebookIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Instagram">
        <IconButton component="a" href={socialMediaAccounts.instagram} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
          <InstagramIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Twitter">
        <IconButton component="a" href={socialMediaAccounts.twitter} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }} >
          <TwitterIcon />
        </IconButton>
      </Tooltip>
    </div >
  );
};

export default SocialMediaLinks;
