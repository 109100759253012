import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

const UserMenu = ({ anchorEl, onClose, handleLogout, user }) => {
  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem>
        Hi, {user.firstName}-{user.accountNumber}
      </MenuItem>
      <MenuItem component={Link} to="/my-bookings">My Bookings</MenuItem>
      <MenuItem component={Link} to="/my-pets">My Pets</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );
};

UserMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  onClose: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    accountNumber: PropTypes.number
    // Add other user properties as needed
  }).isRequired,
};

export default UserMenu;
