import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useDispatch } from 'react-redux';
import { loginUser } from 'slices/userSlice';
import axios from 'axios';

const Login = () => {
  const [loginIdentifier, setLoginIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const dispatch = useDispatch();

  const validateInputs = () => {
    // Validate email or phone number format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (!loginIdentifier.match(emailRegex) && !loginIdentifier.match(phoneRegex)) {
      setLoginError('Please enter a valid email address or a 10-digit phone number.');
      return false;
    }

    if (!password) {
      setLoginError('Please enter your password.');
      return false;
    }

    setLoginError('');
    return true;
  };

  const handleLogin = async () => {
    if (validateInputs()) {
      // Implement your login logic here
      console.log('Logging in');
      try {
        // Example: assuming you have a '/users/login' endpoint for handling login
        const response = await axios.post('http://localhost:5000/users/login', {
          loginIdentifier,
          password,
        });

        // Placeholder logic for successful login
        dispatch(loginUser({
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          email: response.data.user.email,
          phoneNumber: response.data.user.phoneNumber,
          communicationMethod: response.data.user.communicationMethod,
          accountNumber: response.data.user.accountNumber,
          id: response.data.user.id
        }));

        // Reset the login error
        setLoginError('Login Successful', response.data.user.accountNumber);
        window.location.href = 'http://localhost:3000/';
      } catch (error) {
        console.error('Error logging in:', error);

        // Update loginError state with a specific message for login failure
        setLoginError('Invalid Login. Please Try Again.');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div style={{ marginTop: '8vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form style={{ width: '100%', marginTop: '1em' }} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="loginIdentifier"
            label="Email Address or Phone Number"
            name="loginIdentifier"
            autoComplete="email"
            autoFocus
            value={loginIdentifier}
            onChange={(e) => setLoginIdentifier(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {loginError && (
            <Typography variant="body2" color="error" sx={{ marginTop: '0.5em' }}>
              {loginError}
            </Typography>
          )}
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, backgroundColor: '#2196f3', color: '#fff' }}
            onClick={handleLogin}
          >
            Login
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default Login;
