// ContactForm.js

import React, { useState } from 'react';
import ContactInformation from './ContactInformation';
import ServiceDetails from './ServiceDetails';
import PetInformation from './PetInformation';
import PetPictures from './PetPictures';
import NotesComments from './NotesComments';
import AgreementConsent from './AgreementConsent';
import Container from '@material-ui/core/Container';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PetCareComingSoon from 'components/ComingSoon';

const theme = createTheme({
  typography: {
    fontFamily: ['Professional Font', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#ffffff', // Adjust the color as needed
      accent: '#4CAF50'
    },
    secondary: {
      main: '#f50057', // Adjust the color as needed
    },
  },
});

const ContactForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const comingSoon = true;

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  if (comingSoon) {
    return <PetCareComingSoon />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="md"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        {currentStep === 1 && <ContactInformation nextStep={nextStep} />}
        {currentStep === 2 && <ServiceDetails nextStep={nextStep} prevStep={prevStep} />}
        {currentStep === 3 && <PetInformation nextStep={nextStep} prevStep={prevStep} />}
        {currentStep === 4 && <PetPictures nextStep={nextStep} prevStep={prevStep} />}
        {currentStep === 5 && <NotesComments nextStep={nextStep} prevStep={prevStep} />}
        {currentStep === 6 && <AgreementConsent />}
      </Container>
    </ThemeProvider>
  );
};

export default ContactForm;
