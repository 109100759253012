// petHandlers.js

export const handleDelete = async (petId, setDeletePetId, setConfirmationOpen) => {
  setDeletePetId(petId);
  setConfirmationOpen(true);
};

export const handleConfirmationClose = (setConfirmationOpen, setDeletePetId) => {
  setConfirmationOpen(false);
  setDeletePetId(null);
};

export const handleConfirmationConfirm = async (deletePetId, setConfirmationOpen, setDeletePetId, setUserPets) => {
  try {
    await fetch(`http://localhost:5000/pets/delete/${deletePetId}`, {
      method: 'PATCH',
    });

    setUserPets((prevPets) => prevPets.filter((pet) => pet._id !== deletePetId));
  } catch (error) {
    console.error('Error deleting pet:', error);
  } finally {
    setConfirmationOpen(false);
    setDeletePetId(null);
  }
};

export const handleEdit = async (editedPet, setUserPets) => {
  try {
    const response = await fetch(`http://localhost:5000/pets/edit/${editedPet._id.toString()}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editedPet),
    });

    const updatedPet = await response.json();

    // Update the state or handle the updated pet as needed
    setUserPets((prevPets) => prevPets.map((pet) => (pet._id === updatedPet._id ? updatedPet : pet)));
  } catch (error) {
    console.error('Error editing pet:', error);
  }
};
