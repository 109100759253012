// MyPetsPage.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UserPets from 'components/MyPets';
import { selectUser } from 'slices/userSlice';
import { Container, Typography, Grid, Box } from '@mui/material';
import AddPetButton from 'components/AddPetButton';
import AddPetDialog from 'components/AddPetDialog';
import 'styles/MyPetsPage.css';
import { Helmet } from 'react-helmet';

const MyPetsPage = () => {
  const user = useSelector(selectUser);
  const [addPetDialogOpen, setAddPetDialogOpen] = useState(false);

  // New state to manage user pets
  // eslint-disable-next-line no-unused-vars
  const [userPets, setUserPets] = useState([]);

  const handleAddPetClick = () => {
    setAddPetDialogOpen(true);
  };

  const handleAddPetDialogClose = () => {
    setAddPetDialogOpen(false);
  };

  return (
    <div>
      <Helmet>
        <title>My Pets</title>
      </Helmet>
      <Container className='container'>
        <Typography variant="h4" gutterBottom>
          My Pets
        </Typography>
        {user.firstName ? (
          <>
            <Grid container justifyContent="flex-start" className='addButton'>
              <AddPetButton onClick={handleAddPetClick} />
            </Grid>
            <UserPets user={user} className='userPets' setUserPets={setUserPets} userPets={userPets} />
            {/* Pass setUserPets to AddPetDialog */}
            <AddPetDialog open={addPetDialogOpen} onClose={handleAddPetDialogClose} setUserPets={setUserPets} userPets={userPets} />
          </>
        ) : (
          <Box className='loginPrompt'>
            <Typography variant="body1" color="textSecondary">
              Please log in to view your pets.
            </Typography>
            {/* Add a login button or link here */}
          </Box>
        )}
      </Container>
    </div>
  );
};

export default MyPetsPage;
