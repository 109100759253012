// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from 'components/Navbar';
import ContactUsPage from 'pages/ContactUsPage';
import 'index.css';
import PhotosPage from 'pages/PhotosPage';
import AboutUsPage from 'pages/AboutUsPage';
import RatesPage from 'pages/RatesPage';
import TestimonialsPage from './pages/TestimonialsPage';
import ReviewPage from './pages/ReviewPage';
import HomePage from './pages/HomePage';
import Footer from 'components/Footer';
import LoginPage from 'pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import UpdatePets from './pages/UpdatePets';
import MyPetsPage from './pages/MyPetsPage';
import MyBookings from './pages/MyBookings';
//import ProtectedContent from './components/ProtectedContent';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/book-a-service" element={<ContactUsPage />} />
        <Route path="/photos" element={<PhotosPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/services" element={<RatesPage />} />
        <Route path="/testimonials" element={<TestimonialsPage />} />
        <Route path="/review" element={<ReviewPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/pets" element={<UpdatePets />} />
        <Route path="/my-pets" element={<MyPetsPage />} />
        <Route path="/my-bookings" element={<MyBookings />} />
        <Route path="*" element={<NotFoundPage />} />
        {/* Add more routes as needed */}
      </Routes>
      <Footer />
    </Router>

  );
};

export default App;
