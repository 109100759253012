import Review from 'components/Review';
import React from 'react';
import { Helmet } from 'react-helmet';

const ReviewPage = () => {
  return (
    <div>
      <Helmet>
        <title>Review Information</title>
      </Helmet>
      <Review />
    </div>);
};

export default ReviewPage;
