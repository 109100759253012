import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUser } from 'slices/userSlice';
import axios from 'axios';
import {
  Card,
  CardContent,
  Button,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Autocomplete,
} from '@mui/material';
import pino from 'pino';
import { dogBreeds, catBreeds } from 'assets/data/PetBreeds';

const logger = pino();

const AddPet = ({ onClose, setUserPets }) => {
  const user = useSelector(selectUser);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [years, setYears] = useState('');
  const [months, setMonths] = useState('');
  const [breed, setBreed] = useState('');

  const handleAddPet = async () => {
    try {
      // Ensure user is populated before adding a pet
      if (!user.id) {
        logger.error('User not populated. Please fetch user data first.', user);
        return;
      }

      // Ensure at least one of the age fields has a value greater than 0
      if (!(parseInt(years, 10) > 0 || parseInt(months, 10) > 0)) {
        logger.error('At least one of the age fields should be greater than 0.');
        return;
      }

      if (parseInt(months, 10) > 11) {
        logger.error('Months should be between 0 and 11.');
        return;
      }

      // Combine years and months into a formatted age string
      const age = `${parseInt(years, 10)} Years ${parseInt(months, 10)} Months`;
      const newPet = {
        name,
        type,
        age,
        breed,
        owner: user.accountNumber,
        status: 'Active',
      };

      // Make the API call to add a new pet
      const response = await axios.post('http://localhost:5000/pets/add', newPet);

      logger.info('Pet added successfully:', newPet);
      console.log(response);
      setUserPets((prevUserPets) => [...prevUserPets, newPet]);
      // Clear the input fields
      setName('');
      setType('');
      setYears('');
      setMonths('');
      setBreed('');
      // Close the modal
      onClose();
    } catch (error) {
      logger.error('Error adding pet:', error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          maxWidth: 400,
          margin: 'auto',
          padding: 3,
          backgroundColor: '#f7f7f7',
          borderRadius: 8,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h5" mb={2}>
              Add a Pet
            </Typography>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value="cat">Cat</MenuItem>
                <MenuItem value="dog">Dog</MenuItem>
              </Select>
            </FormControl>
            <InputLabel htmlFor="pet-age">Pet Age</InputLabel>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Years"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                  value={years}
                  onChange={(e) => setYears(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Months"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                  value={months}
                  onChange={(e) => setMonths(e.target.value)}
                />
              </Grid>
            </Grid>
            <Autocomplete
              options={type === 'cat' ? catBreeds : dogBreeds}
              value={breed}
              onChange={(_, newValue) => setBreed(newValue)}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Breed"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
              limitTags={5}
              filterOptions={(options, { inputValue }) => {
                if (inputValue.length < 3) {
                  return [];
                }
                return options
                  .filter((option) =>
                    option.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .slice(0, 5);
              }}
              noOptionsText=""
            />
            <Button variant="contained" color="primary" onClick={handleAddPet}>
              Add Pet
            </Button>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

AddPet.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAddPet: PropTypes.func.isRequired,
  setUserPets: PropTypes.func.isRequired,
  userPets: PropTypes.array.isRequired
};

export default AddPet;
