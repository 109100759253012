// AddPetButton.js
import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import 'styles/AddPetButton.css';

const AddPetButton = ({ onClick }) => {

  return (
    <Button variant="contained" className='addButton' onClick={onClick}>
      Add Pet
    </Button>
  );
};

AddPetButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddPetButton;
