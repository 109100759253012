import NotFound from 'components/NotFound';
import React from 'react';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <div >
        <NotFound />
      </div>
    </div >
  );
};

export default NotFoundPage;
